<template>
  <div :class="{ loader: true, fadeout: !isLoading }">Loading ...</div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"],
  mounted() {
    setTimeout(() => {
      this.onload();
      //   this.$router.push("/Pincode");
      //   if (localStorage.getItem("blog_token") != null) {
      //     console.log("Pincode");
      //     this.$router.push("/Pincode");
      //   } else {
      //     console.log("Login");
      //     this.$router.push("/Login");
      //   }
    }, 2000);
  },
  methods: {
    getuserAgent() {
      return this.userAgent;
    },
    onload() {
    //   localStorage.removeItem("fun_token")
      localStorage.removeItem("error_count");
      localStorage.removeItem("error_varify_time");
      if (localStorage.getItem("fun_token")) {
        console.log("checking token");
        this.doChecktoken();
      } else {
        console.log("new user go to connector");
        this.$router.push("/Connector");
      }
    },
    async doChecktoken() {
      let parameter = {
        to: "login",
        method: "check_token",
      };

      await this.$store.dispatch("auth/TransferData", parameter).then(
        (response) => {
          console.log(response);
          if (response.isPin == true) this.$router.push("/Pincode");
          else this.$router.push("/CreatePincode");
        },
        (error) => {
          console.log(error);
          localStorage.removeItem("fun_token");
          this.$router.push("/Connector");
        }
      );
    },
  },
};
</script>

<style>
.loader {
  background-color: #ff00b3;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 5s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
