import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueSimpleAlert from "vue-simple-alert";
import VueHtml2Canvas from 'vue-html2canvas';

//แก้ VueHtml2Canvas
const regeneratorRuntime = require("regenerator-runtime");

Vue.use(VueHtml2Canvas);

Vue.use(VueSimpleAlert);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.prototype.$headers = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      Authorization: "Bearer " + localStorage.getItem("fun_token"),
    },
};


// axios.defaults.baseURL = "http://192.168.20.227/FN2021/cus/Mask/service/";

axios.defaults.baseURL = '/service/';

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded; charset=UTF-8";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

